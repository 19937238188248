import { THEME_CONSTANTS } from '@cof/plastic-components';
import { css } from 'styled-components';
import cssSystem from '@styled-system/css';
import { Theme } from '../../theme';

const getColors = (theme: Theme) => theme.components.verify;

const { COLORS: CN } = THEME_CONSTANTS;

export default {
    mainHeading: CN.capitalOne.blue750,
    background: CN.capitalOne.blue750,
    border: 'none',
    borderColor: 'unset',
    heading: CN.global.white,
    text: CN.global.white,
    listItem: CN.global.white,
    iconColor: CN.global.white,
    buttonVariant: 'action',
    errorPanel: CN.capitalOne.red550,
    appHeader: ({ theme }: { theme: Theme }) => css`
        ${cssSystem({
            backgroundColor: getColors(theme).background,
            borderBottom: '0px',
        })}
    `,
};
