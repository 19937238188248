/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';

import {
    AlertIcon,
    Box,
    BRANDS,
    Button,
    Flex,
    Heading,
    LoadingSpinnerBranded,
    PageRowNoContentPadding,
    Paragraph,
    Text as PlasticText,
    THEME_CONSTANTS as TC,
    useBrand,
} from '@cof/plastic-components';
import config from '@cof/ukweb-config/lib/clientConfig';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { styled, useTheme } from 'styled-components';
import Tooltip from '../../components/ToolTip';
import UnifiedDateForm, { InputValues } from '../../components/UnifiedDateForm';
import { useVerifySubmit } from '../../data';
import { withText } from '../../text/textStore';
import { PIXEL_LOAD_TIMEOUT, ERROR_CODES } from '../../utilities/constants';
import pushToDataLayer, { EVENTS } from '../../utilities/dataLayer/dataLayer';
import { addNewRelicPageAction, setNewRelicAttribute } from '../../utilities/newRelic';
import waitForPixels from '../../utilities/pixels/waitForPixels';
import useCustomerEndpoint from '../../utilities/useCustomerEndpoint/useCustomerEndpoint';
import { useZuko } from '../../utilities/useZuko';
import {
    formSchemaWithRetryVariant,
    getChannel,
    getFormattedDate,
    getSearchParameter,
    getVendorCodeFromUrl,
    isClientError,
} from '../../utils';
import TechnicalError from '../TechnicalError';

export const ErrorPanel = styled(Box)`
    color: ${({ theme }) => theme.components.verify.errorPanel};
    border: 2px solid currentColor;
    text-align: left;
`;

ErrorPanel.displayName = 'ErrorPanel';

export const ErrorText = styled(Button).attrs({
    variant: 'textLinkInline',
    role: 'link',
})`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: inherit;
    text-decoration: underline;
`;

ErrorText.displayName = 'ErrorText';

export const InlineIcon = styled(AlertIcon)`
    vertical-align: text-bottom;
`;

InlineIcon.displayName = 'InlineIcon';

const PageLoadSpinner = () => {
    const theme = useTheme();
    // Takes plastic defaults so can be undefined if not required to be overridden
    const color = theme.components?.verify?.spinner?.color;
    const bg = theme.components?.verify?.spinner?.bg;
    return <LoadingSpinnerBranded data-qa-id="page-loading-spinner" color={color} bg={bg} />;
};

const VerifyWithRetries = () => {
    // Setup react form
    const methods = useForm({ resolver: zodResolver(formSchemaWithRetryVariant) });

    // get config
    const gtm = config.get('gtm');
    const { isEnabled: partnerAgreementEnabled } = config.get('partnerAgreement');

    // theme and brand setup
    const theme = useTheme();
    const brand = useBrand<'capitalOne' | 'ocean' | 'thinkmoney'>();

    // get url params
    const quotationParam = getSearchParameter('quotation');
    const tokenParam = getSearchParameter('token');
    const vendor = getVendorCodeFromUrl();
    const channel = getChannel();
    const batchSMSVendorCode = 'OS5';
    const batchEmailVendorCode = 'OE5';
    const isBRQ = !!vendor && [batchSMSVendorCode, batchEmailVendorCode].includes(vendor) && brand === BRANDS.OCEAN;
    const isBatchSMS = vendor === batchSMSVendorCode && brand === BRANDS.OCEAN;

    const { trackIncorrectKBA, trackCompletionEvent, trackDoubleToken } = useZuko({
        channel,
        brand,
        vendor,
    });

    const [submitVerify, isSubmitting, { error, response }] = useVerifySubmit();

    useEffect(() => {
        if (brand) setNewRelicAttribute('brand', brand);
        if (vendor) setNewRelicAttribute('vendorCode', vendor);
        if (channel) setNewRelicAttribute('channel', channel);
    }, [brand, vendor, channel]);

    const formErrorRef = React.useRef<HTMLElement>();
    const headingRef = React.useRef<HTMLElement>();

    useEffect(() => {
        // after this component mounts, focus the headingRef element
        headingRef.current?.focus();
    }, []);

    useEffect(() => {
        if (gtm.isEnabled) {
            pushToDataLayer({
                event: EVENTS.VERIFY_NEW_SESSION,
                channel,
            });
        }
    }, [channel, gtm.isEnabled]);

    const { capitalOne: capitalOneAgreement, partner: partnerAgreement } = config.get('agreementUrl');
    let agreementUrl = capitalOneAgreement;
    if (partnerAgreementEnabled) {
        agreementUrl = brand === 'capitalOne' ? capitalOneAgreement : partnerAgreement.replace('{partner}', brand);
    }

    useEffect(() => {
        // if client error was returned from submission, focus the form error panel
        if (isClientError(error)) {
            formErrorRef.current?.focus();
            if (gtm.isEnabled) {
                pushToDataLayer({
                    event: EVENTS.VERIFY_KBA_FAIL,
                    channel,
                });
            }

            addNewRelicPageAction('submit-validation-error', { error: 'Incorrect KBA' });
            trackIncorrectKBA();
        }

        // if a token was returned from submission, redirect to agreement url
        if (response?.data?.token) {
            addNewRelicPageAction('Verify Completed');
            trackCompletionEvent();

            const qs = new URLSearchParams(window.location.search);
            qs.set('quotation', response.data.token);
            qs.delete('token');

            const redirect = () => {
                window.location.assign(`${agreementUrl}/?${qs.toString()}`);
            };

            if (gtm.isEnabled) {
                pushToDataLayer({
                    event: EVENTS.VERIFY_KBA_PASS,
                    channel,
                    eventCallback: () => {
                        // once all pixels have loaded, redirect to agreement url
                        waitForPixels().then(({ timeoutOccurred }) => {
                            if (timeoutOccurred) {
                                addNewRelicPageAction('pixel-timeout', {
                                    error: `Pixel timeout (${PIXEL_LOAD_TIMEOUT})`,
                                });
                            }
                            redirect();
                        });
                    },
                });
            } else {
                redirect();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response, error, channel, gtm.isEnabled, agreementUrl]);

    const token: string | undefined = quotationParam || tokenParam;

    // retrieve-customer-name endpoint setup
    const { name, isLoading } = useCustomerEndpoint('orchestration.retrieveCustomerName.baseURL', token, channel);

    if (quotationParam !== undefined && tokenParam !== undefined) {
        trackDoubleToken();
        return <TechnicalError />;
    }

    const attemptsRemaining = isClientError(error) ? error?.response?.data?.attemptsRemaining : undefined;

    const onSubmit: SubmitHandler<InputValues<number>> = (values) => {
        submitVerify({
            token,
            channel,
            answers: {
                customerDateOfBirth: getFormattedDate(values),
            },
        });
    };

    if (error && !isClientError(error)) {
        return <TechnicalError />;
    }

    const clientErrorCode = isClientError(error) ? error?.response?.data?.id : undefined;
    if (clientErrorCode && [ERROR_CODES.ATTEMPTS_EXCEEDED, ERROR_CODES.EXPIRED].includes(clientErrorCode)) {
        const campaign = clientErrorCode === ERROR_CODES.ATTEMPTS_EXCEEDED ? 'dob_validation_failed' : 'expired_token';
        let quotationsUrl: string | undefined;
        if (brand !== 'capitalOne') quotationsUrl = config.get('partnerQuotationsUrl')[brand];

        const currentParamsAsString = new URLSearchParams(window.location.search).toString();
        const redirect = () => {
            window.location.assign(`${quotationsUrl}/?${currentParamsAsString}&utm_campaign=${campaign}`);
        };

        if (gtm.isEnabled && clientErrorCode === ERROR_CODES.ATTEMPTS_EXCEEDED) {
            pushToDataLayer({
                event: EVENTS.KBA_VALIDATION_FAILED,
                eventCallback: () => {
                    redirect();
                },
            });
        } else {
            redirect();
        }
    }

    return isLoading ? (
        <Box as="main" height="100%">
            <PageLoadSpinner />
        </Box>
    ) : (
        <>
            <Box backgroundColor={theme.components.verify.securityBanner} padding="xs" textAlign={['left', 'center']}>
                <Box paddingLeft={['sm', null, null, null]}>
                    <img
                        src={`/assets/svg/${brand.toLowerCase()}_security_icon.svg`}
                        alt=""
                        style={{
                            verticalAlign: 'sub',
                            paddingRight: TC.SPACE.XS,
                            display: 'inline',
                        }}
                        width="20px"
                        height="20px"
                    />
                    <Paragraph style={{ textAlign: 'center', display: 'inline' }}>
                        {' '}
                        This is for <PlasticText fontWeight="medium">security purposes</PlasticText>
                    </Paragraph>
                </Box>
            </Box>
            <PageRowNoContentPadding maxWidth="600px" wrapperBg={theme.components.verify.background} py="sm">
                <Flex>
                    <Box bg={'global.white'} mx="auto" px={['xs', 'sm', 'md', 'lg']}>
                        <Heading
                            size="medium"
                            mb={['sm', 'md', 'md']}
                            p={0}
                            textAlign="left"
                            fontWeight="medium"
                            data-qa-id="customer-name"
                        >
                            Hi
                            {!!name && <span style={{ color: theme.components.verify.nameColor }}>{` ${name}`}</span>}!
                        </Heading>
                        {isBatchSMS && (
                            <>
                                <Paragraph data-qa-id="sms-info-paragraph">
                                    Ocean&apos;s <Tooltip /> has confirmed that you&apos;re now eligible for an Ocean
                                    Credit Card.
                                </Paragraph>
                            </>
                        )}
                        <FormProvider {...methods}>
                            <UnifiedDateForm
                                onSubmit={onSubmit}
                                attemptsRemaining={attemptsRemaining}
                                isSubmitting={isSubmitting}
                                isBRQ={isBRQ}
                            />
                        </FormProvider>
                    </Box>
                </Flex>
            </PageRowNoContentPadding>
        </>
    );
};

const VerifyWithText = withText('VerifyWithRetries')(VerifyWithRetries);

VerifyWithText.displayName = 'VerifyWithRetries';

export default VerifyWithText;
