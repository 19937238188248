import { BRANDS, BrandedFooter, footerContent as plasticFooterContent } from '@cof/plastic-components';
import { useTheme } from 'styled-components';
import addSParameterToLinkUrls from '../../utilities/addSParameterToLinkUrls';

const Footer = () => {
    const theme = useTheme();
    const footerContent = Object.entries(plasticFooterContent).reduce(
        (updatedFooter, [footerBrand, content]) => ({
            ...updatedFooter,
            [footerBrand === BRANDS.CAPITAL_ONE ? 'capitalOne' : footerBrand.toLowerCase()]: {
                links: content ? addSParameterToLinkUrls({ links: content.links }) : [],
                copyright: content ? content.copyright : '',
            },
        }),
        {},
    );
    return (
        <BrandedFooter
            //@ts-expect-error passing null to allowList leads to a special behaviour which is not yet typed in plastic.
            allowList={null}
            footerContent={footerContent}
            variant={theme.components.verify?.footerVariant}
        />
    );
};

export default Footer;
