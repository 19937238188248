import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { ERROR_TYPE } from '../../utilities/constants';
import { addNewRelicError } from '../../utilities/newRelic';
import ServerError from './Variants/ServerError';

const TechnicalError = ({ errorType = ERROR_TYPE.SERVER_ERROR }) => {
    useEffect(() => {
        addNewRelicError('technical-error', { sawError: true });
    }, []);

    switch (errorType) {
        default:
            return <ServerError />;
    }
};

TechnicalError.propTypes = {
    errorType: PropTypes.oneOf(Object.values(ERROR_TYPE)),
};

TechnicalError.displayName = 'TechnicalError';

export default TechnicalError;
