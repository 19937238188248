export const PREFERENCE = {
    ONLINE_STATEMENTS: 'online-statements',
    MARKETING_CONTACT_PERMISSION: 'marketing-contact-permission',
};

export type Brand = (typeof ALLOWED_BRANDS)[number];

export const ALLOWED_BRANDS = [
    'capitalOne',
    'littlewoods',
    'luma',
    'ocean',
    'postoffice',
    'very',
    'asos',
    'thinkmoney',
] as const;

export const CHARACTERS = {
    tab: 9,
    enter: 13,
    escape: 27,
    spacebar: 32,
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
};

export const ERROR_TYPE = {
    SERVER_ERROR: 'ServerError',
};

export const ERROR_CODES = {
    INCORRECT: 401,
    ATTEMPTS_EXCEEDED: 403,
    EXPIRED: 410,
};

export const HOSTED_PARTNERS = ['luma', 'littlewoods', 'very', 'asos'];

export const PIXEL_LOAD_TIMEOUT = 500;
