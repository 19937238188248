/* eslint-disable arrow-body-style */
import { useState, useEffect } from 'react';

import axios, { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import { getConfig } from './config';

type SubmitData = {
    token?: string;
    channel: string;
    answers: {
        customerDateOfBirth: string;
    };
};

export type ApiResponse = { token?: string; id?: number; attemptsRemaining?: number };

interface State<T, U> {
    execCount: number;
    execInput: T | null;
    error: AxiosError<U> | Error | string | null;
    response: null | AxiosResponse<U>;
    loading: boolean;
}

const applyArgToAxiosConfig = <T>(arg: T | null, config: (arg: T | null) => void) => {
    const resultConfig = typeof config === 'function' ? config(arg) : config;
    if (typeof resultConfig === 'string') {
        return { url: resultConfig };
    }

    return resultConfig;
};

const useRequestCallback = <T, U>(axiosConfigOrFactory: (arg: T | null) => void, axiosConfigOverrides = {}) => {
    const [state, setState] = useState<State<T, U>>({
        execCount: 0,
        execInput: null,
        loading: false,
        error: null,
        response: null,
    });

    const exec = (arg: T | null) =>
        setState({
            ...state,
            loading: true,
            error: null,
            execInput: arg,
            execCount: state.execCount + 1,
        });

    useEffect(() => {
        if (!state.execCount) {
            return;
        }

        async function doRequest() {
            const requestConfig = {
                ...applyArgToAxiosConfig<T>(state.execInput, axiosConfigOrFactory),
                ...axiosConfigOverrides,
            };

            try {
                const response = await axios(requestConfig);
                setState({ ...state, loading: false, response });
            } catch (error) {
                if (isAxiosError(error) || error instanceof Error || typeof error === 'string')
                    setState({ ...state, loading: false, error });
            }
        }

        doRequest();
        // eslint-disable-next-line
    }, [state.execCount]);

    const retry = () => exec(state.execInput);
    const { loading, error, response, execCount, execInput } = state;

    return [exec, loading, { error, retry, response, execCount, input: execInput }] as const;
};

export const usePostCallback = <T, U>(axiosConfigOrFactory: (arg: T | null) => void) =>
    useRequestCallback<T, U>(axiosConfigOrFactory, { method: 'POST' });

export const useVerifySubmit = () => {
    return usePostCallback<SubmitData | null, ApiResponse>((data: SubmitData | null) => {
        return { ...getConfig('elevateToken'), data };
    });
};
