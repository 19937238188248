import { THEME_CONSTANTS } from '@cof/plastic-components';
import { css } from 'styled-components';
import cssSystem from '@styled-system/css';
import { Theme } from '../../theme';

const getColors = (theme: Theme) => theme.components.verify;

const { COLORS: CN } = THEME_CONSTANTS;

export default {
    mainHeading: CN.ocean.blue800,
    background: CN.global.white,
    border: '2px solid',
    borderColor: CN.ocean.blue450,
    heading: CN.ocean.blue800,
    footerVariant: 'lightSecondary',
    errorPanel: CN.ocean.red550,
    securityBanner: '#c2ecff',
    continueButton: CN.ocean.green500,
    continueButtonHover: '#408404',
    continueButtonDisabled: '#9CC080',
    appHeader: ({ theme }: { theme: Theme }) => css`
        ${cssSystem({
            backgroundColor: getColors(theme).background,
            borderBottom: '0px',
        })}
    `,
    spinner: { color: CN.ocean.blue800, bg: CN.global.white },
};
