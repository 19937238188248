import { FooterLinkProps } from '@cof/plastic-components';
import { getSearchParameter } from '../utils';

const addSParameterToLinkUrls = ({ links }: { links: FooterLinkProps[] }) =>
    links.map((link) => {
        const url = new URL(link.url);
        const sParameter = getSearchParameter('s');
        if (sParameter) {
            url.searchParams.set('s', sParameter);
        }
        return {
            ...link,
            url: url.toString(),
        };
    });

export default addSParameterToLinkUrls;
