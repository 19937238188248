import { ERROR_TYPE } from '../../utilities/constants';
import capitalOne from './capitalOne';

/* eslint-disable max-len */
const ExternalLinks = {
    QUICKCHECK: 'https://very-quickcheck.capitalone.co.uk',
};

const content = {
    DocumentHead: {
        Title: 'Credit Card Application - Very',
        Description: 'Complete our application form for a Very credit card. Response in 60 seconds.',
    },
    TechnicalError: {
        [ERROR_TYPE.SERVER_ERROR]: {
            ...capitalOne.TechnicalError[ERROR_TYPE.SERVER_ERROR],
            Button: {
                Text: 'Do another QuickCheck',
                URL: ExternalLinks.QUICKCHECK,
            },
        },
    },
};

export default {
    ...capitalOne,
    ...content,
};
