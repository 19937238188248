import pushToDataLayer, { EVENTS } from './dataLayer/dataLayer';
import { addNewRelicPageAction, EVENTS as NR_EVENTS } from './newRelic';
import { CookieData } from './types/types';

const doNewRelicActions = ({ data }: { data: CookieData }) => {
    if (data['2']) {
        addNewRelicPageAction(NR_EVENTS.GDPR_PREF_ALLOWS_2);
    }
    if (data['3']) {
        addNewRelicPageAction(NR_EVENTS.GDPR_PREF_ALLOWS_3);
    }
};

const doGtmActions = ({ data }: { data: CookieData }) => {
    // We send exactly one of GDPR_PREF_ALLOWS_2_3, GDPR_PREF_ALLOWS_2, GDPR_PREF_ALLOWS_3 to avoid overwrites
    if (data['2'] && data['3']) {
        pushToDataLayer({ event: EVENTS.GDPR_PREF_ALLOWS_2_3 });
    } else if (data['2']) {
        pushToDataLayer({ event: EVENTS.GDPR_PREF_ALLOWS_2 });
    } else if (data['3']) {
        pushToDataLayer({ event: EVENTS.GDPR_PREF_ALLOWS_3 });
    }
};

interface ActionFunctions {
    [key: string]: () => void;
}

const onCookieManagementEvent = (action: string, data?: CookieData): void => {
    const functions: ActionFunctions = {
        BANNER_INIT: () => addNewRelicPageAction(NR_EVENTS.GDPR_PREF_ALLOWS_1),
        BANNER_FIRST_RENDER: () => addNewRelicPageAction(NR_EVENTS.BANNER_OPEN),
        OPEN_COOKIE_MODAL: () => addNewRelicPageAction(NR_EVENTS.MODAL_OPEN),
        ACCEPT_ALL_COOKIES: () => addNewRelicPageAction(NR_EVENTS.GDPR_ACCEPT_ALL),
        REJECT_ALL_COOKIES: () => addNewRelicPageAction(NR_EVENTS.GDPR_REJECT_ALL),
        SET_COOKIE_PERMISSIONS: () => {
            if (data) {
                doNewRelicActions({ data });
                doGtmActions({ data });
            }
        },
    };
    functions[action]?.();
};

export default onCookieManagementEvent;
