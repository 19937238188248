import { z } from 'zod';
import { BRANDS } from '@cof/plastic-components';
import { InputValues } from './components/UnifiedDateForm/UnifiedDateForm';
import { ReactNode } from 'react';
import { QueryParams } from './utilities/types/types';
import { AxiosError, isAxiosError } from 'axios';
import { ApiResponse } from './data/data';

export const getSearchParameter = (parameter: string): string | undefined => {
    const query: QueryParams = Object.fromEntries(new URLSearchParams(window.location.search));
    const lowerCaseParams = new URLSearchParams();
    Object.entries(query).forEach(([key, value]) => lowerCaseParams.append(key.toLowerCase(), value));
    return lowerCaseParams.get(parameter) || undefined; // undefined is added because query.get returns null if not found
};

export const getChannel = (): string => {
    const rawChannel = getSearchParameter('channel') || getSearchParameter('utm_medium');

    return rawChannel && /^(email|sms|qcp|per)[0-9a-z]{0,5}$/i.test(rawChannel) ? rawChannel.toUpperCase() : 'NONE';
};

export const getVendorCodeFromUrl = () => {
    interface ValidVendorCode {
        (vendorCode?: string): string | undefined;
    }

    const validVendorCode: ValidVendorCode = (vendorCode) =>
        vendorCode && (/^[0-9A-Za-z]{3,}$/.test(vendorCode) ? vendorCode : undefined);

    const s = getSearchParameter('s');
    const sVendorCode = s?.substring(8, 11)?.replace('x', '');

    return validVendorCode(getSearchParameter('v')) || validVendorCode(sVendorCode);
};

interface PadDate {
    (d: number): string | undefined;
}

export const padDate: PadDate = (d: number) => d.toString().padStart(2, '0');

export const getFormattedDate = (arg: InputValues<number>): string => {
    const { dateOfBirth: value } = arg;
    return `${value.year}-${padDate(value.month)}-${padDate(value.day)}`;
};

export const isClientError = (error: AxiosError | Error | string | null): error is AxiosError<ApiResponse> => {
    return isAxiosError<ApiResponse>(error) && error.status === 400;
};

export const formSchema = z.object({
    dateOfBirth: z.object({
        day: z
            .string()
            .min(1)
            .transform((val) => Number(val))
            .refine((val) => val > 0 && val <= 31),
        month: z
            .string()
            .min(1)
            .transform((val) => Number(val))
            .refine((val) => val > 0 && val <= 12),
        year: z
            .string()
            .min(1)
            .transform((val) => Number(val))
            .refine((val) => val >= 1900 && val < new Date().getFullYear()),
    }),
});

export const AGE_RANGE_ERROR = 'age_range_error';
export const formSchemaWithRetryVariant = z.object({
    dateOfBirth: z
        .object({
            day: z
                .string()
                .min(1)
                .transform((val) => Number(val))
                .refine((val) => val > 0 && val <= 31),
            month: z
                .string()
                .min(1)
                .transform((val) => Number(val))
                .refine((val) => val > 0 && val <= 12),
            year: z
                .string()
                .min(1)
                .transform((val) => Number(val))
                .refine((val) => val >= 1900 && val < new Date().getFullYear()),
        })
        .refine(
            (data) => {
                const { day, month, year } = data;
                if ([day.toString(), month.toString(), year.toString()].includes('')) {
                    return true;
                }
                const birthDate = new Date(year, month - 1, day);
                const today = new Date();
                let age = today.getFullYear() - birthDate.getFullYear();
                const monthDiff = today.getMonth() - birthDate.getMonth();
                const dayDiff = today.getDate() - birthDate.getDate();

                if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                    age -= 1;
                }

                return age >= 18 && age <= 95;
            },
            {
                message: AGE_RANGE_ERROR,
            },
        )
        .refine(
            (data) => {
                const { day, month, year } = data;
                const lastDayOfMonth = new Date(year, month, 0).getDate();
                return day > 0 && day <= lastDayOfMonth;
            },
            {
                path: ['day'],
            },
        ),
});

interface ShouldDisplayVerifyWithRetries {
    (brand: string): boolean;
}

export const shouldDisplayVerifyWithRetries: ShouldDisplayVerifyWithRetries = (brand) =>
    brand === BRANDS.OCEAN || brand === BRANDS.THINK_MONEY.toLowerCase();

export const str = (a: ReactNode) => (typeof a === 'string' ? a : '');
