import template from '@cof/acq-text-ukweb/lib/plugins/transformers/template';
import {
    brandProviderFactory,
    globalStyles,
    styleReset,
    themeProviderFactory,
    useBrand,
} from '@cof/plastic-components';
import '@cof/plastic-components/fonts.css';
import React, { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';
import App from './App';
import DocumentHead from './components/DocumentHead';
import useBrandStrategy from './hooks/useBrandStrategy';
import text from './text/brands';
import { TextStore } from './text/textStore';
import * as themes from './themes';
import { ALLOWED_BRANDS } from './utilities/constants';

const GlobalStyle = createGlobalStyle`
    ${styleReset}
    ${globalStyles}

    html, body, #root{
        height: 100%;
    }
`;

const BrandProvider = brandProviderFactory({
    brandProviderStrategy: useBrandStrategy,
    defaultBrand: 'capitalOne',
    brandAllowList: ALLOWED_BRANDS,
});

const ThemeProvider = themeProviderFactory({
    themeLoader: async (brand) => import(`./themes/${brand}/index.ts`),
    useBrandSelector: () => useBrand(ALLOWED_BRANDS),
    defaultBrand: 'capitalOne',
    defaultTheme: themes.capitalOne,
});

const BrandTextStore: React.FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const brand = useBrand(ALLOWED_BRANDS);
    return (
        <TextStore
            transformers={{
                template,
            }}
            value={text[brand]}
        >
            {children}
        </TextStore>
    );
};

const container = document.getElementById('root');
if (!container) throw Error('Could not create document root');

const root = createRoot(container);
root.render(
    <BrandProvider>
        <ThemeProvider>
            <BrandTextStore>
                <GlobalStyle />
                <DocumentHead />
                <App />
            </BrandTextStore>
        </ThemeProvider>
    </BrandProvider>,
);
