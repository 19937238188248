/* eslint-disable react/jsx-props-no-spreading */
import {
    useFloating,
    useInteractions,
    useClick,
    useRole,
    useDismiss,
    FloatingPortal,
    FloatingArrow,
    arrow,
    FloatingFocusManager,
} from '@floating-ui/react';
import { useState, useRef } from 'react';
import { Link, Paragraph, Text, THEME_CONSTANTS as TC } from '@cof/plastic-components';
import styled, { css } from 'styled-components';

const StyledPopup = styled.div`
    ${({ theme: { breakpoints } }) => css`
        top: 20px;
        left: -70px;
        width: 260px;
        padding: 10px;
        background-color: #eeeeee;
        border-radius: 10px;
        position: absolute;

        ${breakpoints.mq.tabletMin} {
            width: 400px;
        }
    `}
`;

const Tooltip = () => {
    // tooltip setup
    const arrowRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const { refs, context, floatingStyles } = useFloating<HTMLElement>({
        open: isOpen,
        onOpenChange(nextOpen, _, reason) {
            setIsOpen(nextOpen);
            if (reason === 'escape-key' || reason === 'outside-press') {
                refs.domReference.current?.focus();
            }
        },
        placement: 'bottom',

        middleware: [
            arrow({
                element: arrowRef,
            }),
        ],
    });
    const click = useClick(context);
    const dismiss = useDismiss(context);

    const role = useRole(context, {
        role: 'tooltip',
    });

    const tooltipBackgroundColor = '#EEEEEE';
    const { getReferenceProps, getFloatingProps } = useInteractions([click, role, dismiss]);
    return (
        <>
            <Link
                ref={refs.setReference}
                {...getReferenceProps()}
                style={{
                    color: TC.COLORS.ocean.blue450,
                    padding: 0,
                    backgroundColor: 'transparent',
                    border: 0,
                }}
                // @ts-expect-error - plastic Link might need too take an `as` prop
                as="button"
                data-qa-id="tooltip-trigger"
            >
                smart search
            </Link>
            {isOpen && (
                <FloatingPortal>
                    <FloatingFocusManager context={context} visuallyHiddenDismiss="click to dismiss">
                        <div
                            ref={refs.setFloating}
                            style={{
                                ...floatingStyles,
                                zIndex: 5,
                            }}
                            {...getFloatingProps()}
                            data-qa-id="tooltip"
                        >
                            <StyledPopup>
                                <FloatingArrow
                                    ref={arrowRef}
                                    context={context}
                                    fill={tooltipBackgroundColor}
                                    width={30}
                                    height={25}
                                    staticOffset="8%"
                                />
                                <Paragraph fontSize="textSmallMobile">
                                    After you check your eligibility for a loan or credit card, Ocean&apos;s smart
                                    search automatically keeps track of products you qualify for.
                                </Paragraph>
                                <Paragraph fontSize="textSmallMobile">
                                    This clever technology indicates that{' '}
                                    {/* @ts-expect-error investigate fontSize typing in plastic*/}
                                    <Text fontWeight="semiBold" fontSize="inherit">
                                        you are now eligible
                                    </Text>{' '}
                                    for an Ocean Credit Card.
                                </Paragraph>
                            </StyledPopup>
                        </div>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </>
    );
};

export default Tooltip;
