import ukwebconfig from '@cof/ukweb-config/lib/clientConfig';
import { Config } from '../../env/config';

export type Orchestration = Config['orchestration'];
export interface Endpoint {
    baseURL: string;
    timeout: number;
}

export type ReqHeaders = Record<string, string>;

export const getConfig = (endpoint: keyof Orchestration): Endpoint & { headers: ReqHeaders } => {
    const orch = ukwebconfig.get('orchestration');

    const confg = orch[endpoint];
    return {
        ...confg,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    };
};
