import axios from 'axios';
import { useState, useEffect } from 'react';
import { useBrand } from '@cof/plastic-components';
import useConfig from '../useConfig/useConfig';
import { Config } from '../../../env/config';
import { addNewRelicError } from '../newRelic';
import { Dict, PathKey } from '@cof/ukweb-config/lib/clientConfig';

interface FetchFirstNameParams {
    baseURL: Dict;
    token?: string;
    brand: string;
    channel?: string;
}

const fetchFirstName = async ({ baseURL, token, brand, channel }: FetchFirstNameParams) => {
    if (!token) return '';

    try {
        if (typeof baseURL !== 'string') throw Error('Error baseURL not provided');
        const res = await axios.post(baseURL, {
            token,
            channel,
            partner: brand,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        const returnedFirstName = res?.data?.customerFirstName;
        if (returnedFirstName) {
            return returnedFirstName;
        }
        return '';
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_) {
        addNewRelicError('axios-error', { clientTimestamp: Date.now(), sawError: true });
        return '';
    }
};

interface UseCustomerEndpointReturn {
    name: string | undefined;
    isLoading: boolean;
}

const useCustomerEndpoint = (
    configURL: PathKey<Config>,
    token?: string,
    channel?: string,
): UseCustomerEndpointReturn => {
    const [name, setName] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const brand = useBrand();

    const {
        values: [baseURL],
    } = useConfig([configURL]);

    useEffect(() => {
        fetchFirstName({ baseURL, token, brand, channel })
            .then(setName)
            .finally(() => {
                setIsLoading(false);
            });
    }, [baseURL, token, brand, channel]);

    return { name, isLoading };
};

export default useCustomerEndpoint;
